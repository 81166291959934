/* src/components/AudioSplitter.css */
/* .audio-splitter {
  margin-left: 70px; 
  padding: 20px;
  position: relative; 
} */

.audio-splitter {
  display: flex;
  flex-direction: column; 
  justify-content: right;
 
 
  height: 100vh; /* Full height of the screen */
  padding: 20px; /* Padding for left and right space */
 
}

.audio-splitter h4 {
  max-width: 30%; /* Limit the paragraph width */
  word-wrap: break-word; /* Ensure long words or links wrap properly */
  padding: 0 50px; /* Additional padding inside the paragraph */
  font-size: 18px; /* Adjust font size for better readability */
  line-height: 1.6; /* Add some line-height for better spacing between lines */
  margin-top: 150px; 
  justify-content: end;
 
  
}


.status-messages{
  margin-top: 5%;

}
.status-messages p {
 
  margin-bottom: 10px; /* Add spacing between status messages */
 
 
}

.audio-splitter .upload-button {
  margin-top: 20px; /* Adjust space between p and upload button */
}

.overlay .circular-progress {
  margin-top: 20px; /* Space above the progress spinner */
}

.result {
  margin-top: 20px;
}


.upload-button {
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to center exactly */
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
  background-color: #1a82e2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 
              0 1px 2px rgba(0, 0, 0, 0.2); 
  background-image: linear-gradient(to bottom, #1f3c4a, #1c2833);
}

.upload-button:hover {
  background-color: #14213d;
}

.progress-bar {
  height: 20px;
  background-color: #3498db;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.progress-text {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: white;
}

.upload-status {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.media-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.media-item button {
  margin: 5px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.media-item button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .upload-button {
      width: 100px;
      height: 100px;
      font-size: 16px;
  }
}

.progress-container {
  width: 80%;
  max-width: 500px;
  height: 20px;
  /* background-color: #f3f3f3; */
  background: rgba(27, 19, 36, 0.9); /* Gray background */
  border-radius: 10px;
  overflow: hidden;
  /* margin: 10px 0; */
  margin: 0 auto;
}

.progress-bar {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  color: white;
  margin-top: 2px;
}

.upload-status {
  color: white;
  margin-top: 20px;
  font-size: 18px;
}

.afterError-btn{
  display: flex;
  color: red;
  background-color: #1c2833;
  margin-left: 20px;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50px;
}

.afterError-btn:hover{
 color: white;
}

.circular-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  background: transparent;
  border: none;
}

.overlay .close-btn:hover {
  color: #ff0000; /* Red on hover */
}

.overlay .progress-bar {
  margin: 2px 0;
  height: 20px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .overlay {
      width: 90%; /* Ensure the overlay takes up 90% width on smaller screens */
      height: 70%; /* Ensure the overlay takes up 90% height on smaller screens */
      
      margin-bottom: 20px;
      cursor: pointer;
      padding: 10px;
  }
  .footer-container {
    width: 90%;
    flex-direction: horizontal; /* Stack buttons vertically on smaller screens */
    align-items: center;
  }

  .audio-box {
    flex-direction: column;
    width: 100%; /* Ensure full width for mobile */
    height: auto; /* Let height adjust to content */
  }

  .fancy-button {
    width: 50%; /* Full width for better tap targets */
    margin-bottom: 10px; /* Add spacing between buttons */
    font-size: 14px; /* Smaller font size for mobile */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .center-box {
    width: 50%;
    margin: 10px; /* Add vertical margin */
    height: 70px; /* Smaller height for mobile */
  }

  .audio-splitter h4 {
    text-align: center;
    position: fixed;
   
    left: 10%; /* Center horizontally */
    /* transform: translate(-50%, -50%); Adjust the button to be exactly centered */
    align-items: center;
    height: 100%;
    max-width: 80%;
    margin-bottom: 20px;
    top:-15%;
  }
  
}


@media (max-width: 480px) {
  .fancy-button {
    font-size: 12px; /* Even smaller font size for very small screens */
    padding: 8px; /* Adjust padding */
  }

  .center-box {
    height: 60px; /* Further reduce the height */
  }
}

/* below added to move the player on the footer of the overlay */


/* Overlay for entire screen
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
} */


/* Overlay CSS */
.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
 
  margin-bottom: 50px;
  
  transform: translate(-50%, -50%);
  margin-left: 30px;
  background-color: rgba(27, 19, 36, 0.9); /* Gray background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(235, 233, 233, 0.5);
  z-index: 1000;
  width: 90%; /* Make the overlay occupy 90% of the screen */
  height: 70%; /* Make the overlay occupy 90% of the screen */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

/* Footer container for audio player and buttons */
.footer-container {
  position: relative;
  /* width: 100%; */
  padding: 20px;
  
  background-color: #181c20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.footer-button-container{
  display: flex;
  flex-direction: column;
  margin: 2px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #2b2b2c, #17181a);

}

.footer-button-separator {
  height: 1px;
  background-color: white;
}

.audio-box {
  width: 60%;
  height: 100px; /* Adjust height */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Fancy 3D Buttons */
.fancy-button {
  background: #262929; 
  border: none;
  padding: 15px 20px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
}
.media-sel-button{
  background: #262929; 
  border: none;
  padding: 15px 20px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
}

.media-sel-button.selectedvoc {
  background-color: #14ace9;
  color: white;
}
.media-sel-button.selectedmuc {
  background-color: #b221cf;
  color: white;
}
.media-sel-button:hover{
  background-color: #1d0422;
}

.fancy-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.1);
}

.fancy-button:hover {
  background-color: #1abc9c;
}

/* 3D Effect for the center box */
.center-box {
  background-color: #1c2833;
  height: 100px;
  flex-grow: 1;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #2b2b2c, #17181a);
}


/*88*/

/* AudioSplitter.css */
.footer-container2 {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between buttons and audio box */
  padding: 10px;
  height: 50px; /* Set height to match buttons */
}

.button2 {
  height: 100%; /* Match the height of the footer */
  padding: 10px 20px;
  color: white;
  margin: 1px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 3D look */
  /* background-image: linear-gradient(to bottom, #2b2b2c, #17181a); */
}

.button2:hover{
  background-image: linear-gradient(to bottom, #2b2b2c, #3460b8);
}

.vocal-button2 {
  background-color: #3d8bd4;
}

.music-button2{
  background-color: #2a3c4d;; /* Change this to your preferred pink shade */
}

.audio-box2 {
  position: relative;
  /* background-color: #1c2833; */
  background-color: transparent;
  flex-grow: 1; /* Take up remaining space */
  margin: 0 10px; /* Add margin around the audio box */
  padding: 10px;
  /* border: 1px solid #ccc; /* Optional border */
  /* border-radius: 10px; */
  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2); */
   /* background-image: linear-gradient(to bottom, #2b2b2c, #17181a);  */
}
.audio-box2 audio {
  width: 75%;
  margin: 0 auto; /* Center the audio element */
  background-color: transparent; 
  border: none;
}

.audio-box2-underline{
  width: 98%;
  
  height: 5px;
  margin: 0 auto; /* Center the underline */
  position: absolute;
  bottom: 0;
  /* left: 0; */
  background-color: white;
}