/* AboutUs.css */
.about-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
    text-align: center;
    padding: 20px; /* Ensure some padding so text doesn’t stick to the edges */
    box-sizing: border-box;
}
