/* src/components/Sidebar.css */
.sidebar {
    width: 50px;
    position: fixed;
    left: 0;
    top: 50px; /* Adjust according to the top bar height */
    bottom: 0;
    background: #1c2833; 
    padding: 10px;
    color: white;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  }

 
  .menu-button {
    display: none; /* Hide by default */
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 24px;
    cursor: pointer;
    color: white; /* Dark blue color for the hamburger icon */
    z-index: 1001; /* Ensure it stays on top */
  }
  
  .sidebar ul {
    list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  }
  
  .sidebar ul li {
    margin: 30px 2px;
    padding: 2px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 
  0 1px 2px rgba(0, 0, 0, 0.2); /* Layered shadow for depth */
  justify-content: center;

  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icon and text */
  }
  
  .sidebar ul li a:hover {
    color: #c0c0c0; /* Light color on hover */
    background-color: #1c2833;
  }
  
  .menu-button {
    display: none; /* Hide by default */
    position: fixed;
    top: 10px;
    left: 20px;
    font-size: 28px;
    cursor: pointer;
    color: white; /* Dark blue color for the hamburger icon */
    z-index: 1001; /* Ensure it stays on top */
  }

  /* src/components/Sidebar.css */
[data-tooltip] {
    position: relative;
  }
  
  [data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    font-size: 12px;
   
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    border-bottom: 2px solid #070707; /* White separator line */
    transition: background-color 0.3s, box-shadow 0.3s;
    position: relative;
  }
  
  .menu-item:last-child {
    border-bottom: none; /* Remove separator for the last item */
  }
  
  .menu-item:hover {
    background-color: #1c2833; /* Background color on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 3D effect */
  }
  
  .menu-icon {
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
    transition: transform 0.3s;
  }

  .menu-text {
    font-size: 18px; /* Text size */
    font-family: 'Open Sans', sans-serif;
  }

  .menu-item:hover .menu-icon {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
  }
  
  
  @media (max-width: 768px) {
    .sidebar {
      width: 50px; /* Adjust as needed */
      transform: translateX(-100%); /* Hidden by default */
      z-index: 2000;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 
      0 1px 2px rgba(0, 0, 0, 0.2); /* Layered shadow for depth */
    }
    
    
      
      .sidebar.closed {
        left: -50px; /* Slide out to the left */
      }
      
    .sidebar.open {
        left: 0; /* Slide in from the left */
      transform: translateX(0); /* Show when open */
    }
    
    .menu-button {
      display: block; /* Show the hamburger menu icon on mobile */
    }
    .upload-button {
        top: 60px; /* Adjust based on the height of your top bar */
        left: 10px;
        transform: none;
        right: auto;
        z-index: 1000;
      }
      .menu-text {
        display: none; /* Hide text on smaller screens */
      }
  }
  