/* src/components/TopBar.css */
.top-bar {
    background: #1c2833; 
    right: 50%;
    color: white;
    padding: 10px;
    text-align: center;
  
  }
  
  .logo {
    height: 30px; /* Adjust size as needed */
    width: 30px;
    margin-right: 5px;
    margin-left: 40px;
    
    border-radius: 50%; /* This makes it circular */
    object-fit: cover; 
}
  @media (max-width: 768px) {
    .top-bar h1{
        margin: 50px;
    }
  
  }