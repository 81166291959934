/* src/components/Footer.css */
.footer {
    background: #14213d; 
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000; /* Ensure it stays on top */
  }
  