/* src/styles/App.css */
/* src/App.css */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px; /* Default font size */
  color: white;
 
}

h1, .app-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  color: wheat
}

h2, .section-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #3498db; /* Blue color */
}

p, .paragraph-text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color:white
}

button, .upload-button {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}


.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #273746; /* Dark blue color */
  color: white; /* White text for contrast */
  background: linear-gradient(to bottom, #121213, #1c2833); /* Gradient background */
}

.main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
  padding: 20px;
  flex: 1;
}

/* src/styles/App.css */
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Height of the top bar */
  background: #14213d; 
  color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 1000; /* Ensure it stays on top */
}




.upload-button, .progress-container {
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust the button to be exactly centered */
  z-index: 1000; /* Ensure it stays on top */
  padding: 10px 20px;
  background: #1c2833; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
}

.progress-container{

   background: rgba(27, 19, 36, 0.9); /* Gray background */;  
  top : 30%;
 height: 50px;
  width: 250px; /* Adjust as needed */
  text-align: center;
  
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0; /* No margin on mobile */
  }
 
  .upload-button {
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    right: auto;
  }

}
